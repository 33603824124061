import React, { useState } from 'react'

import { makeStyles } from '@mui/styles'
import { Grid, Tab, Tabs, Theme } from '@mui/material'
import { AppSetting } from '~screens/App/App'
import { useHistory } from 'react-router'
import SearchComponent from '~shared/components/SearchComponent'

interface Props {
  items: AppSetting[]
  user?: any
  changeSearch?: (value?: string) => void
}
const HeaderComponent: React.FC<Props> = (props) => {
  const history = useHistory()

  const [activeTab, setActiveTab] = useState<string>(props.items[0].path)

  const changeTab = (value: string) => {
    setActiveTab(value)
    history.replace(value)
  }

  const useStyles = makeStyles((theme: Theme) => ({
    header: {
      width: '100%',
      overflow: 'hidden',
      height: '350px',
      alignItems: 'center',
    },
    headerImage: {
      width: '100%',
    },
    search: {
      padding: '10px 0',
    },
  }))
  const classes = useStyles()
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.header}>
            <img className={classes.headerImage} src='images/header2.jpg' />
          </div>
        </Grid>
        <Grid item xs={8}>
          <Tabs
            value={activeTab}
            onChange={(event, value) => {
              changeTab(value)
            }}
            aria-label='navigation'
          >
            {props.items.map((item: AppSetting) =>
              <Tab
                key={item.path}
                value={item.path}
                label={item.title}
                wrapped
              />,
            )}
          </Tabs>
        </Grid>
        <Grid item xs={4} alignItems='center' className={classes.search}>
          <SearchComponent changeSearch={(value?: string) => props.changeSearch && props.changeSearch(value)} />
        </Grid>
      </Grid>
    </>
  )
}

export default HeaderComponent
