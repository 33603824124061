import de from './de.json'
import en from './en.json'

localStorage.setItem('language', 'de-DE')

const resources = {
  de: de,
}

export default resources
